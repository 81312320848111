<template>
  <div category-tab>
    <button v-for="(item, i) in list"
      :key="item + i"
      :style="{width: cellWidth+'px'}"
      :class="{selected: i === selectedIdx}"
      @click="$emit('click', {item, index: i})" v-ripple>
      <slot :item="item"></slot>
    </button>
  </div>
</template>

<script>
import ripple from '@/directive/ripple';

export default {
  name: 'CategoryTab',
  directives: { ripple },
  props: {
    list: { type: Array, default: () => ([]) },
    selectedIdx: { type: Number, default: 0 },
    cellWidth: { type: Number, default: 142 },
  },
  data: () => ({
    hoverIdx: -1,
  }),
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[category-tab] { .rel; .flex; .w(100%); border-bottom: solid 1px #d8d8d8; .h(fit-content);
  > button {.flex; .items-center; .justify-center; .h(60); .p(18, 0); .fs(16); .c(#777); cursor: pointer;
    border-bottom: solid 4px #fff;
    &.selected {.c(@black-primary); .bold; border-bottom: solid 4px @black-primary;
      &:hover {border-bottom: solid 4px @black-primary; }
    }
    //&:focus {border-top: dashed 1px rgba(44,129,255,.3); border-left: dashed 1px rgba(44,129,255,.3); border-right: dashed 1px rgba(44,129,255,.3); }
    &:hover {border-bottom: solid 4px rgba(@black-primary, 0.4);}
  }

  @media (@tp-down) {
    > button { .h(48); .fs(12); }
  }

}
</style>
