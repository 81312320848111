<template>
  <div recommend-lesson @click="$emit('click')">
    <CdnImg v-if="thumbnailUrl && !cdnFailed" class="thumbnail" :src="thumbnailUrl" @fail="cdnFailed = true" />
    <img v-else class="thumbnail" :src="'/img/coaching/recommend-lesson-thumbnail.png'" />
    <div class="info-holder">
      <p>{{ title }}</p>
      <div class="rating-holder">
        <span class="rating">{{ rating }}</span>
        <div class="rating-star" v-for="i in 5" :key="i">
          <span v-if="i <= rating">★</span>
          <span v-else>☆</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CdnImg from '@shared/components/common/CdnImg.vue';

export default {
  name: 'recommendLesson',
  components: { CdnImg },
  props: { thumbnailUrl: { type: String, default: '' }, title: { type: String, default: '' }, rating: { type: Number, default: 0 } },
  data: () => ({
    cdnFailed: false,
  }),
};
</script>

<style lang="less" scoped>
@import '~@/less/coaching.less';

[recommend-lesson] { .w(100%); .flex; flex-direction: column;
  .thumbnail {.w(100%); .h(175); .br(4); object-fit: cover; object-position: center;}
  .info-holder { .rel; .h(102); .pt(16);
    > p {.fs(16); .lh(24); .c(#191919); font-weight: 500; .ellipsis(2); line-break: anywhere;}
    > .rating-holder {.flex; .c(#ff757d); .items-center; .h(24); .mt(6);
      .rating {.chakra; .fs(12); .bold; .mr(5); .lh(16);}
      .rating-star {.fs(12); .lh(16); .bold;}
    }
  }
}
</style>
