<template>
  <Slider recommend-lesson-list :info="list" ref="slider" :option="options" :spaceBetween="16">
    <template v-slot:slide="{info}">
      <RecommendLesson :rating="info.reviewRating" :title="getProductName(info)" :thumbnail-url="info.historyThumbnail" @click="itemHandler(info)" />
    </template>
  </Slider>
</template>

<script>
import Slider from '@shared/components/common/Slider.vue';
import RecommendLesson from '@/views/components/coaching/lesson/RecommendLesson.vue';

export default {
  name: 'RecommendLessonList',
  components: { Slider, RecommendLesson },
  props: {
    list: { type: Array, default: () => ([]) },
  },
  computed: {
    options() {
      const breakpoints = {};
      const spaceBetween = 16;
      breakpoints[1260] = {
        slidesPerView: 4,
        slidesPerGroup: 4,
      };
      breakpoints[1024] = {
        slidesPerView: 3,
        slidesPerGroup: 3,
      };
      breakpoints[768] = {
        slidesPerView: 2,
        slidesPerGroup: 2,
      };
      breakpoints[0] = {
        slidesPerView: 1,
        slidesPerGroup: 1,
      };
      return { breakpoints, spaceBetween };
    },
  },
  methods: {
    itemHandler(item) {
      this.$emit('click', item);
    },
    /**
     * @param {RecommendLesson} info
     */
    getProductName(info) {
      return info?.lessonProduct?.name ?? '';
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[recommend-lesson-list] {
  .swiper-wrapper {.flex; }
  .swiper-slide { .flex; .justify-center;}

  @media (max-width: 1279px) {
    .p(0, 48);
  }
  @media (@tp-down) {
    .p(0, 20); .pr(40);
  }
}

</style>
