<template>
  <div purchase-area-pop-in :data-is-coach="isCoach">
    <!-- 모바일-태블릿 기준 화면 -->
    <div class="purchase-con-n-button">
      <div class="purchase-contents">
        <hr />
        <div class="purchase-popin-scroll">
          <div class="purchase-options">
            <radio-group-em v-model="lessonProductId" :options="lessonProductOptions" theme="radio-blue" v-slot="{option}" :disabled="!isOnline" id="purchase-area-pop-in">
              <div class="purchase-item">
                <em>
                  <span>{{ option.lessonProduct.name }}</span>
                  <span class="descriptive">{{ readLessonTime(option.lessonProduct) }}</span>
                </em>
                <span class="margin"></span>
                <point-icon :value="option.lessonProduct.price" :show-currency="false" :shape="lessonProductId === option.value ? 'purple-transparent-small' : 'normal-small'" />
              </div>
            </radio-group-em>
          </div>
          <div class="purchase-description">
            <h3 class="lesson-time-title">
              <svg-timer />
              수업 가능시간
            </h3>
            <pre>{{ lessonTime }}</pre>
          </div>
          <hr />
        </div>
        <div class="purchase-price" v-show="currentPrice !== -1">
          <point-icon :value="currentPrice" shape="transparent-alt-big" />
        </div>
        <div class="purchase-coach-offline" v-show="isOffline">
          <img src="/img/coaching/emoji-angel.svg" alt="emoji-angel" />
          <p>코치님이 오프라인 상태이므로<br />1:1 문의를 통해 코치님과 채팅 할 수 있습니다.</p>
        </div>
        <div class="purchase-coach-offline" v-show="isCoaching">
          <img src="/img/coaching/emoji-angel.svg" alt="emoji-angel" />
          <p>코치님이 오프라인 상태이므로<br />1:1 문의를 통해 코치님과 채팅 할 수 있습니다.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PurchaseArea from '@/views/components/coaching/coach-detail/PurchaseArea.vue';

export default {
  name: 'PurchaseAreaPopIn',
  extends: PurchaseArea,
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[purchase-area-pop-in] {.bgc(@c-white);.br(8);
  .purchase-title {.c(@black-primary);.fs(16);.mb(16);}
  .purchase-con-n-button {
    > * + * {.mt(40);}
  }
  .purchase-cta-area {.flex;}
  .purchase-cta-area [color-button] {flex: 1;.h(48);}
  .purchase-cta-area [color-button]:nth-child(2) {.ml(8)}
  .purchase-contents, .purchase-popin-scroll {
    > * + * {.mt(16);}
  }
  .purchase-contents hr {border-top: solid 1px @gray-100;}
  .purchase-description {.bgc(@gray-100);.c(@gray-600);.p(16, 16, 18)}
  .purchase-description pre {.fs(12);.lh(20);.mt(14);white-space: pre-wrap;}
  .lesson-time-title {.fs(14);.lh(14);font-weight: 400;.flex;.flex-ai(center);}
  .lesson-time-title svg {.wh(15);.mr(5.5);}
  .purchase-price {text-align: right;}
  .radio-item {.w(100%);.p(16, 0, 16, 8)}
  .radio-item label { .w(100%);}
  .radio-item label .descriptive {.c(@gray-600);.mt(auto);.mb(auto);
    // margin
    &:before {content: '';.ml(4);}
  }
  .purchase-item {.flex;}
  .purchase-item .margin {flex: 1;}
  .purchase-item > em > span { word-break: break-all;}
  .purchase-coach-offline {.flex;.items-center;
    > p {.fs(14);.lh(20.27);.c(@red-desc)}
    img {.wh(32);.mr(8)}
  }
  .purchase-popin-scroll {
    .max-h(calc(100vh - 380px)); // 하단 플로팅 영역이 모두 표시되고 나서 화면 상단에 닿기까지의 거리
    overflow-y: scroll;
  }

  .radio-item label em {.fs(16);.lh(23.17);}
  .radio-item label .descriptive {.fs(12);.lh(17.38)}
}
</style>
