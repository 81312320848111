<template>
  <div profile-area>
    <div class="pc-left">
      <div class="avatar-wrap" :class="{online:isOnline, coaching:isCoaching}">
        <avatar :info="coach.user" :defaultImgType="'fruit'" dimension="240x240" userProfile />
      </div>
    </div>
    <div class="pc-right content">
      <div class="content-top">
        <div class="avatar-wrap mobile-only" :class="{online:isOnline, coaching:isCoaching}">
          <avatar class="mobile-only" :info="coach.user" :defaultImgType="'fruit'" dimension="100x100" userProfile />
        </div>
        <p class="coach-nickname">{{ userNickname }}</p>
      </div>
      <div class="coach-stats">
        <rating-star-point :value="reviewRating" v-if="hasReview">
          <span class="descriptive">{{ reviewCountText }}</span>
        </rating-star-point>
        <p><em>수업</em>
          <span>{{ lessonCountText }}개</span>
        </p>
        <p><em>코치 찜</em>
          <span>{{ bookmarkCountText }}건</span>
        </p>
      </div>
      <div class="tag-group">
        <tag v-for="tag in tags" :key="tag.coachTagId" :theme="`profile-${tag.priority === 1 ? 'hot': 'base'}`">{{ tag.name }}</tag>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@shared/components/common/Avatar.vue';
import { commaDecimal } from 'shared/utils/numberUtils';
import RatingStarPoint from '@/views/components/coaching/RatingStarPoint.vue';
import Tag from '@/views/components/coaching/Tag.vue';

export default {
  components: { RatingStarPoint, Avatar, Tag },
  props: {
    coachInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    coach() {
      return /** @type {Coach} */ this.coachInfo;
    },
    user() {
      return this.coach?.user ?? {};
    },
    userNickname() {
      return this.coach?.user?.nickname ?? '';
    },
    isOnline() {
      return this.coach?.status === 'ONLINE';
    },
    isCoaching() {
      return this.coach?.status === 'COACHING';
    },
    hasReview() {
      return this.coach?.reviewRating > 0 && this.coach?.reviewCount > 0;
    },
    reviewRating() {
      return this.coach?.reviewRating ?? 0;
    },
    reviewCount() {
      return this.coach?.reviewCount ?? 0;
    },
    reviewCountText() {
      return `(후기 ${commaDecimal(this.reviewCount)}개)`;
    },
    lessonCount() {
      return this.coach?.lessonCount ?? 0;
    },
    lessonCountText() {
      return commaDecimal(this.lessonCount);
    },
    bookmarkCount() {
      return this.coach?.bookmarkCount ?? 0;
    },
    bookmarkCountText() {
      return commaDecimal(this.bookmarkCount);
    },
    tags() {
      return /** @type{CoachTagAlt[]} */ this.coach?.tags ?? [];
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
@c-black: #191919;
@em-blue: #3180ff;
@c-online-green: #0ce757;
@c-coaching-blue: #6aa6ff;

[profile-area] {
  .flex;flex-direction: row;.noto;

  .avatar-wrap {display: inline-block;.wh(120);}
  .avatar-wrap.mobile-only {.wh(50);}
  .pc-left [avatar] { .wh(100%);.br(32); border: solid 2px #fff; }
  [avatar].mobile-only {.wh(100%);.br(12); border: solid 0.83px #fff;}
  //.avatar-wrap.mobile-only {float:left;}

  .coach-nickname { .fs(34);.lh(44.2);font-weight: 500;.c(@c-black);word-break: break-all;flex: 1;.flex;.items-center;}
  .content { flex: 1;}
  [rating-star-point] span {.fs(14);.lh(14);}
  [rating-star-point] .rating { .c(@c-black);font-weight: bold;}
  [rating-star-point] .descriptive {.c(@c-text-dark)}

  .coach-stats {.flex;.items-center;}
  .coach-stats > *:not([rating-star-point]) {.fs(14);.lh(14);}
  .coach-stats > * > em { .c(@em-blue);.mr(4)}
  .coach-stats > * + * {.ml(20);.rel;
    &:before {content: '';.wh(4);.bgc(@gray-400);.abs;transform: translate(-50%, -50%);.t(50%);.l(-8);.br(50%)}
  }
  .tag-group [tag] {.ml(0);.mr(8);.mb(8);}
  .avatar-wrap {.rel;
    &:after { content: '';.wh(20);.abs;.bgc(@c-desc-gray);.br(50%);.lb(0);border: solid 2px #fff; }
  }
  .avatar-wrap.mobile-only:after {.wh(10);border: solid .83px #fff;}

  .avatar-wrap.online:after {
    .bgc(@c-online-green);
  }

  .avatar-wrap.coaching:after {
    .bgc(@c-coaching-blue);
  }

  // 데탑 전용
  @media (@tl-up) {
    .mobile-only {.hide}
    .pc-left {.mr(16);}
    .content-top {.mb(14);}
    .coach-stats {.mb(14);}
  }
  // 모바일
  @media (@tp-down) {
    .content-top {.flex;.mb(15);}
    .coach-stats {.mb(15)}
    .pc-left {.hide}
    .coach-nickname { .fs(20);.lh(24);.ml(8);}
  }
}
</style>
