<template>
  <div purchase-area :data-is-coach="isCoach">
    <!-- 데스크탑 기준 화면. 다른 화면은 extend하여 표현할 것 -->
    <h2 class="purchase-title">수업 신청하기</h2>
    <div class="purchase-con-n-button">
      <div class="purchase-contents">
        <div class="purchase-options">
          <radio-group-em v-model="lessonProductId" :options="lessonProductOptions" theme="radio-blue" v-slot="{option}" :disabled="purchaseUnavailable" id="purchase-area">
            <div class="purchase-item">
              <em>
                <span class="product-name">{{ option.lessonProduct.name }}</span>
                <span class="descriptive">{{ readLessonTime(option.lessonProduct) }}</span>
              </em>
              <span class="margin"></span>
              <point-icon :value="option.lessonProduct.price" :show-currency="false" :shape="lessonProductId === option.value ? 'purple-transparent-small' : 'normal-small'" />
            </div>
          </radio-group-em>
        </div>
        <div class="purchase-description">
          <h3 class="lesson-time-title">
            <svg-timer />
            수업 가능시간
          </h3>
          <pre>{{ lessonTime }}</pre>
        </div>
        <hr />
        <div class="purchase-price" v-show="currentPrice !== -1">
          <point-icon :value="currentPrice" shape="transparent-alt-big" />
        </div>
        <div class="purchase-coach-offline" v-show="isOffline && !isCoach">
          <img src="/img/coaching/emoji-wink.svg" alt="emoji-angel" />
          <p>코치님이 오프라인 상태이므로<br />1:1 문의를 통해 코치님과 채팅 할 수 있습니다.</p>
        </div>
        <div class="purchase-coach-offline" v-show="isCoaching && !isCoach">
          <img src="/img/coaching/emoji-wink.svg" alt="emoji-angel" />
          <p>코치님이 수업중 상태이므로<br />1:1 문의를 통해 코치님과 채팅할 수 있습니다.</p>
        </div>
      </div>
      <div v-show="isCoach" class="unavailable-text">
        <img src="/img/coaching/emoji-wink.svg" alt="emoji" />
        <p>코치님은 수업 신청을 할 수 없습니다.</p>
      </div>
      <div class="purchase-cta-area">
        <basic-button theme="dark" @click="onChat" :disabled="isCoach">1:1 상담</basic-button>
        <basic-button theme="red" @click="onPurchase" :disabled="purchaseUnavailable">수업 신청하기</basic-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getter } from '@shared/utils/storeUtils';
import PointIcon from '@/views/components/coaching/PointIcon.vue';
import { SvgTimer } from '@/views/graphics/LazyLoadings';
import RadioGroupEm from '@/views/components/coaching/RadioGroupEm.vue';
import { ModalCoupon } from '@/views/components/LazyLoadings';
import { getLessonPrice } from '@/utils/coachUtils';
import { CAUSE_LESSON_ORDERS } from '@/constants/payment';
import PointBuyModal from '@/views/components/coaching/PointBuyModal.vue';
import BasicButton from '@/views/components/common/button/BasicButton.vue';

export default {
  components: { BasicButton, RadioGroupEm, SvgTimer, PointIcon },
  data: () => ({
    lessonProductId: '',
    bundleSize: 1,
  }),
  props: {
    coachInfo: {
      type: Object,
      default: () => ({}),
    },
    defaultLessonProductId: {
      type: String,
      default: '',
    },
  },
  computed: {
    isCoach: getter('auth', 'isCoach'),
    isLogin: getter('auth', 'isLogin'),
    ci() {
      return /** @type{CoachDetail} */ this.coachInfo;
    },
    lessonTime() {
      return this.ci?.lesson?.description?.lessonTime ?? '';
    },
    lessonProducts() {
      return /** @type{CoachLessonProduct[]} */ this.ci?.lesson?.lessonProducts ?? [];
    },
    lessonProductOptions() {
      return this.lessonProducts.map(lp => ({
        value: lp.lessonProductId,
        lessonProduct: lp,
      }));
    },
    /**
     * @description
     * 현재 선택된 lessonProduct.
     */
    lp() {
      const currentProduct = this.lessonProducts?.find(lp => lp.lessonProductId === this.lessonProductId);
      return /** @type{CoachLessonProduct} */ currentProduct || {};
    },
    purchaseUnavailable() {
      return !this.isOnline || this.isCoach;
    },
    currentPrice() {
      return this.lp.price ?? -1;
    },
    isOnline() {
      return this.ci?.status === 'ONLINE';
    },
    isOffline() {
      return this.ci?.status === 'OFFLINE';
    },
    isCoaching() {
      return this.ci?.status === 'COACHING';
    },
    hasLessonProducts() {
      return this.lessonProducts?.length >= 1;
    },
  },
  beforeMount() {
    if (this.isOnline && !this.isCoach) {
      if (this.hasLessonProducts) this.lessonProductId = this.defaultLessonProductId !== '' ? this.defaultLessonProductId : this.lessonProducts?.[0]?.lessonProductId;
    }
  },
  methods: {
    /**
     * @param {CoachLessonProduct} lessonProduct
     */
    readLessonTime(lessonProduct) {
      const time = this.readTime(lessonProduct?.time ?? {});
      return `(${time})`;
    },
    /**
     * @param{LessonDuration} lessonDuration
     * */
    readTime(lessonDuration) {
      const timeUnits = {
        HOURS: '시간',
        MINUTES: '분',
      };
      return [lessonDuration.value, timeUnits[lessonDuration?.unit]].join('');
    },
    async onChat() {
      if (!this.isLogin) {
        this.$services.auth.oAuthLogin(true);
        return;
      }
      if (this.isCoach) return;
      this.$services.chat.openChatWithCoach(this, this.ci?.coachId);
    },
    async onPurchase() {
      if (!this.isLogin) {
        this.$services.auth.oAuthLogin(true);
        return;
      }
      if (this.isCoach) return;

      // lessonbuy.vue 에서 복사해옴
      let purchaseLessonArg;
      const neededPrice = getLessonPrice(this.currentPrice, this.bundleSize);
      try {
        /** GA 전자상거래 추적코드 */
        this.$gtag.event('begin_checkout', {
          value: neededPrice * 10,
          currency: 'KRW',
          items: [{
            item_id: this.lessonProductId,
            item_name: this.lp.name,
            price: this.lp.price * 10,
            quantity: this.bundleSize,
            item_brand: this.lp.coachId,
            item_category: 'REALTIME_COACHING',
            discount: ((this.lp.price * this.bundleSize) - neededPrice) * 10,
          }],
        });

        const coupon = /** @type{LessonCoupon} */ await this.$modal(ModalCoupon, {
          bundleSize: this.bundleSize,
          lessonProductId: this.lessonProductId,
          originalPrice: neededPrice,
        });
        purchaseLessonArg = { lessonProductId: this.lessonProductId, bundleSize: this.bundleSize, couponId: coupon?.coupon?.couponId };
        await this.$services.coaching.purchaseLesson(purchaseLessonArg);
        this.$services.chat.openChatWithCoach(this, this.ci?.coachId, { isPurchaseEvent: true });

        /** GA 전자상거래 추적코드 */
        this.$gtag.event('purchase', {
          value: neededPrice * 10,
          currency: 'KRW',
          items: [{
            item_id: this.lessonProductId,
            item_name: this.lp.name,
            price: this.lp.price * 10,
            quantity: this.bundleSize,
            item_brand: this.lp.coachId,
            item_category: 'REALTIME_COACHING',
            discount: ((this.lp.price * this.bundleSize) - neededPrice) * 10,
          }],
        });

        this.$emit('close');
      } catch (err) {
        if (err?.[1] === 'NOT_SUFFICIENT_CASH') {
          console.error('cash not sufficient');
          this.$toast(err, { type: 'fail' });
          this.$store.commit('point/setPointBuyCause', { type: CAUSE_LESSON_ORDERS, purchaseLessonArg, neededPrice, coachId: this.ci?.coachId });
          // this.$modal(PointBuyModal);
          alert('포인트 충전이 불가합니다.');
        } else {
          console.error(err);
          throw err;
        }
      }
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[purchase-area] {.p(32, 20, 40);.bgc(#fff);.br(8);border: solid 1px @gray-200;
  .purchase-title {.c(@black-primary);.fs(16);.mb(16);}
  .purchase-con-n-button {border-top: solid 1px @gray-100;
    > * + * {.mt(40);}
  }
  .unavailable-text {.flex; .items-center; .mt(16);
    > p {.ml(8); .c(#f00); .fs(14); }
  }
  .purchase-cta-area {.flex;}
  .purchase-cta-area [basic-button] {flex: 1;.h(48);}
  .purchase-cta-area [basic-button]:nth-child(2) {.ml(8)}
  .purchase-contents {
    > * + * {.mt(16);}
  }
  .purchase-contents hr {border-top: solid 1px @gray-100;}
  .purchase-description {.bgc(@gray-100);.c(@gray-600);.p(16, 16, 18)}
  .purchase-description pre {.fs(12);.lh(20);.mt(14);white-space: pre-wrap;
    .max-h(100);overflow-y: auto; // 5문장 정도가 최대 길이
  }
  .lesson-time-title {.fs(14);.lh(14);font-weight: 400;.flex;.flex-ai(center);}
  .lesson-time-title svg {.wh(15);.mr(5.5);}
  .purchase-price {text-align: right;}
  .radio-item {.w(100%);.p(16, 0, 16, 8);}
  .radio-item label { .w(100%);}
  .radio-item label .descriptive {.c(@gray-600);.mt(auto);.mb(auto);.fs(12);
    // margin
    &:before {content: '';.ml(4);}
  }
  .purchase-item {.flex;}
  .purchase-item .margin {flex: 1;}
  .purchase-item > em > span { word-break: break-all;}
  .purchase-coach-offline {.flex;.items-center;
    > p {.fs(14);.lh(20.27);.c(@red-desc)}
    img {.wh(32);.mr(8)}
  }
}
</style>
