<template>
  <div float-purchase-area>
    <client-only>
      <basic-button theme="dark" @click="onChat" v-if="!isCoach" :disabled="isCoach">1:1 상담</basic-button>
    </client-only>
    <basic-button theme="red" @click="onPurchase" :disabled="purchaseUnavailable">수업 신청하기</basic-button>
  </div>
</template>

<script>

import { getter } from 'shared/utils/storeUtils';
import BasicButton from '@/views/components/common/button/BasicButton.vue';

export default {
  components: { BasicButton },
  props: {
    coachInfo: {
      type: Object,
      default: () => ({}),
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isCoach: getter('auth', 'isCoach'),
    isLogin: getter('auth', 'isLogin'),
    isOffline() {
      return this.ci?.status === 'OFFLINE';
    },
    ci() {
      return /** @type{CoachDetail} */ this.coachInfo;
    },
    purchaseUnavailable() {
      // 무조건 하단 수업 신청하기 영역을 열 수 있어야 함
      if (!this.isOpen) return false;
      // 실제 구매하는 단계에서는 offline, 코치 여부에 따라 비활성화
      return this.isOffline || this.isCoach;
    },
  },
  methods: {
    async onChat() {
      if (!this.isLogin) {
        this.$services.auth.oAuthLogin(true);
        return;
      }
      if (this.isCoach) return;
      this.$services.chat.openChatWithCoach(this, this.ci?.coachId);
    },
    onPurchase() {
      if (!this.isOpen) {
        this.$emit('open');
        return;
      }
      // 이미 모달이 열려있는 경우 결제 실행
      this.$emit('dispatchToContent', 'onPurchase');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[float-purchase-area] {.flex;
  [basic-button] {flex: 1;.h(48);}
  [basic-button]:nth-child(1) {.mr(8);}
}
</style>
