<template>
  <button button-bookmark :class="{active}" alt="bookmark" @click="$emit('click')">
    <svg-bookmark />
    <span>즐겨찾기</span>
  </button>
</template>

<script>
import SvgBookmark from '@/views/graphics/svg-bookmark.vue';

export default {
  components: { SvgBookmark },
  props: { active: { type: Boolean, default: false } },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[button-bookmark] {display: inline-flex;.items-center;.h(36);.br(8);.pr(4);
  svg {.wh(24);.mr(9)}
  span {.fs(12)}
  &:not(.active) {
    svg .fill-inner {.hide}
  }
  &:not(.active):not(:hover):not(.hover) {
    svg .fill-inner { .hide; }
    color: #a4a3ae;
  }
  &.active {
    color: #2c81ff;
    &:hover, &.hover {
      svg { .o(.5); }
      svg .fill-inner { .hide; }
    }
  }
  &:focus {outline-color: rgba(44, 129, 255, .3); outline-width: 1px; outline-style: dashed;}
}
</style>
