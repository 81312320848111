<template>
  <div coach-detail :class="{'with-top-banner': hasTopBanner }">
    <div class="coach-detail-wrap">
      <div class="btn-prev-holder">
        <button class="btn-prev" @click="prevBtnHandler">
          <img src="/img/icon/icon-arrow-down-dark.svg">
        </button>
        <span>코치님 목록</span>
      </div>
      <div class="coach-detail-inner" v-if="coachInfo">
        <div class="coach-info">
          <div class="btn-holder">
            <button-bookmark @click="onBookmark" :active="isLiked" />
            <button-share @click="onShare" />
          </div>
          <profile-area :coach-info="coachInfo" />
          <CategoryTab ref="tab" class="tab" :list="tabs" :selectedIdx="selectedTabIdx" @click="tabHandler">
            <template v-slot="{item}">
              <span>{{ item.text }}</span>
            </template>
          </CategoryTab>
          <div ref="tabContent" class="tab-content">
            <router-view v-bind="{isEditable, coachInfo}" @update-coach-info="onUpdateCoachInfo" />
          </div>
        </div>
        <div class="side">
          <purchase-area :coach-info="coachInfo" />
        </div>
      </div>
      <div class="recommend-lesson">
        <Heading>
          <template #title>이 수업은 어떠세요?</template>
        </Heading>
        <RecommendLessonList :list="recommendLessonList" @click="recommendLessonItemHandler" />
      </div>
    </div>
  </div>
</template>

<script>
import { commaDecimal } from 'shared/utils/numberUtils';
import { getter } from '@shared/utils/storeUtils';
import { addDuration } from '@shared/utils/timeUtils';
import { getPersistentStorage, getSessionStorage } from "shared/modules/ObjectStorage";
import CategoryTab from '@/views/components/common/CategoryTab.vue';
import RecommendLessonList from '@/views/components/coaching/lesson/RecommendLessonList.vue';
import Heading from '@/views/components/common/text/Heading.vue';
import ProfileArea from '@/views/components/coaching/coach-detail/ProfileArea.vue';
import PurchaseArea from '@/views/components/coaching/coach-detail/PurchaseArea.vue';
import FloatPurchaseArea from '@/views/components/coaching/coach-detail/FloatPurchaseArea.vue';
import PurchaseAreaPopIn from '@/views/components/coaching/coach-detail/PurchaseAreaPopIn.vue';
import ButtonBookmark from '@/views/components/common/button/ButtonBookmark.vue';
import ButtonShare from '@/views/components/common/button/ButtonShare.vue';
import coach from '@/mixins/coach';

import { ModalRecommend } from "@/views/components/LazyLoadings";
import floatPopIn from "@/mixins/floatPopIn";

export default {
  name: 'CoachDetail',
  mixins: [coach, floatPopIn()],
  components: { ButtonShare, ButtonBookmark, PurchaseArea, ProfileArea, RecommendLessonList, CategoryTab, Heading },
  data: () => ({
    coachInfo: null,
    recommendLessonList: [],
    prevTabPos: 0,
    lastScrollY: 0,
  }),
  metaInfo() {
    return {
      title: this.$t('meta.coachDetail.title', { nickname: this.coachInfo?.user?.nickname }),
      meta: [
        { vmid: 'title', content: this.$t('meta.coachDetail.title', { nickname: this.coachInfo?.user?.nickname }) },
        { vmid: 'description', content: this.$t('meta.coachDetail.description', { coachDescription: this.coachInfo?.description }) },
        { vmid: 'url', content: this.$t('meta.coachDetail.url', { coachId: this.coachId }) },
        { vmid: 'site_name', content: this.$t('meta.siteName') },
        { vmid: 'type', content: this.$t('meta.type') },
        {
          vmid: 'image',
          content: this.coachInfo?.user?.profileImage === 'profile_image.png'
            ? this.$t('meta.image') : `${process.env.VUE_APP_AWS_MEMBERSHIP_URL}/${this.coachInfo?.user?.profileImage}`,
        },
        { vmid: 'keywords', content: this.$t('meta.coachDetail.keywords', { nickname: this.coachInfo?.user?.nickname }) },
      ],
    };
  },
  computed: {
    isLogin: getter('auth', 'isLogin'),
    userId: getter('auth', 'userId'),
    isCoach: getter('auth', 'isCoach'),
    hasTopBanner: getter('info', 'hasTopBanner'),
    myCoachId: getter('auth', 'myCoachId'),
    isEditable() {
      return this.userId === this.coachInfo?.user?.userId;
    },
    reviewCount() {
      return commaDecimal(this.coachInfo?.reviewCount ?? 0);
    },
    coachId() {
      return this.$route.params?.coachId || '';
    },
    tab() {
      return this.tabs?.[this.selectedTabIdx]?.key;
    },
    selectedTabIdx() {
      const tab = this.$route?.params?.tab || 'introduction';
      return this.tabs.findIndex(e => e.key === tab || e.keyAlt === tab);
    },
    tabs() {
      return [{ key: 'introduction', text: '코치님 소개' }, { key: 'review', text: `리뷰(${this.reviewCount})` }, { key: 'activity', text: '코치님 활동' }, { key: 'class', keyAlt: 'class-edit', text: '수업 소개' }];
    },
    isLiked() {
      return this.coachInfo?.isLiked ?? false;
    },
  },
  methods: {
    onUpdateCoachInfo(coachInfo) {
      this.coachInfo = coachInfo;
    },
    async onBookmark() {
      if (!this.isLogin) {
        return this.$services.auth.oAuthLogin(true);
      }
      this.$services.coaching[this.isLiked ? 'unlikeCoach' : 'likeCoach'](this.coachId);
      this.coachInfo.bookmarkCount += (this.isLiked ? -1 : 1);
      this.coachInfo.isLiked = !this.isLiked;
    },
    onShare() {
      this.shareCoachUrl(this.coachId);
    },
    prevBtnHandler() {
      this.$router.push({ name: 'CoachFinder' });
    },
    tabHandler(tab) {
      const { item } = tab;
      const routeNames = { introduction: 'CoachDetailIntroduction', review: 'CoachDetailReview', activity: 'CoachDetailActivity', class: 'CoachDetailClass' };

      /** GA 전자상거래 추적코드 */
      this.$gtag.event('click_coach_detail_tab', {
        event_category: 'coach_detail',
        event_label: 'coach_detail_tab',
        value: routeNames[item?.key],
      });

      this.$router.push({ name: routeNames[item?.key], params: { coachId: this.coachId, tab: item?.key } });
    },
    scrollHandler() {
      const tabNode = this.$refs?.tab;
      const tabContentEl = this.$refs?.tabContent;
      let hiddenHeader = false;
      if (window.scrollY > 100 && window.scrollY > 0) {
        hiddenHeader = this.lastScrollY - window.scrollY < 0;
        this.lastScrollY = window.scrollY;
      } else hiddenHeader = false;

      if (!tabNode || !tabContentEl) return;
      if (hiddenHeader) tabNode.$el.classList.add('hidden-header');
      else tabNode.$el.classList.remove('hidden-header');
      if ((tabNode.$el?.getBoundingClientRect()?.top ?? 0) - 64 <= 0 && !tabNode.$el.classList.contains('tab-float')) {
        const barEl = document.createElement('div');
        barEl.className = 'bar';
        tabNode.$el.appendChild(barEl);
        this.prevTabPos = window.scrollY;
        tabNode.$el.classList.add('tab-float');
        tabContentEl.classList.add('tab-float');
      } else if (this.prevTabPos > window.scrollY) {
        const barEl = tabNode.$el.querySelector('.bar');
        if (barEl) tabNode.$el.removeChild(barEl);
        tabNode.$el.classList.remove('tab-float');
        tabContentEl.classList.remove('tab-float');
      }
    },
    recommendLessonItemHandler(item) {
      const coachId = item?.coachId;
      this.$router.push({ name: 'CoachDetail', params: { coachId } });
      this.$scroll.scrollTo(0);
    },
    async induceRecommendTutor() {
      // COG-477 코치 모달을 4번 닫았을 경우 추천 페이지로 유도
      // 추천 코치 정보를 가져오는 방법:
      // 1. 기존에 코치 추천 받았는지 여부를 persistent storage에서 확인
      // 2. persistent storage 에 기록되지 않았으나, 로그인 된 경우 자체적으로 API 를 통해 확인. -> 해당 기능이 CoachRecommend에서 분리되어있지 않으므로 이 페이지에서 자체적으로 진행
      // 코치 추천 API 만료 기간에 대한 저장 방식:
      // 1. getPersistentStorage('coach-recommend').set('surveyId')를 이용한 방식 -> 내부적으로 구현 방식이 복잡해서 CoachDetail.vue에서는 CoachRecommend.vue의 내용에 간섭하지 않음
      // 2. setTutorRecommendExpire()를 이용한 방식 -> API 로 조회된 내용에 접근할 때 CoachDetail.vue 내부에서만 사용.
      // 1과 2의 내용을 비교하여 타당한 숫자 하나를 코치 추천 API 만료 기간 비교에 사용한다.
      const tutorModalCloseAmount = this.$services.cookie.getTutorModalCloseAmount();
      // 기존에 코치 추천 받았는지 여부를 조회했는지 여부를 확인
      const recommendQueried = this.$services.cookie.getTutorRecommendQueried();

      if (this.$services.auth.isLogin && !recommendQueried) {
        try {
          // ! 해당 내용 작업시 COG-477 내용 확인 필요
          const recommendation = await this.$services.coaching.getMyRecommendationCoaches();
          if (recommendation?.expiredDatetime) this.$services.cookie.setTutorRecommendExpire(recommendation?.expiredDatetime);
        } catch (err) {
          console.log('error on induceRecommendTutor()', err);
          // 스펙에 에러 처리 방식에 대한 언급 없고, 기능상 에러 메시지 표시가 적당하지 않음.
          // 해당 에러에 대한 기획 필요
        }
        this.$services.cookie.setTutorRecommendQueried(true);
      }
      const expiryTimeAlt = getPersistentStorage('coach-recommend').get('surveyId')?.expiredDatetime ?? -1;
      const recommendExpiryDate = this.$services.cookie.getTutorRecommendExpire();
      const recommendExpiryTime = recommendExpiryDate ? recommendExpiryDate?.getTime() : -1;
      const currentTime = new Date().getTime();
      const expiryTimeFinal = Math.max(recommendExpiryTime, expiryTimeAlt);
      const recommendExpired = expiryTimeFinal < currentTime;
      // 임시로 코치 추천 거절에 대한 시간을 코치 추천 모달 노출 여부 추적에 사용.
      // 기존에는 setTutorRecommendExpire가 코치 추천 페이지 접근시에도 동작했으나, 해당 기능이 어떠한 이유로 유실된 것으로 추정.
      // 나중에 변수 및 메소드명을 기능에 맞게 수정할 것
      const lastRejected = this.$services.cookie.getTutorRecommendRejected();
      const past24HoursAfterReject = lastRejected ? addDuration(lastRejected, { hours: 24 }) < currentTime : true;

      // 추천을 안 받았거나
      // 추천을 받았으면 추천이 유효하지 않을 때
      // 추천 유도를 거절했다면 24시간이 지난 상태일 때
      if (recommendExpired && past24HoursAfterReject) this.$services.cookie.setTutorModalCloseAmount(tutorModalCloseAmount + 1);

      if (tutorModalCloseAmount >= 3) {
        // 버그: 현재는 새로고침을 해야만 $modal의 결과가 초기화된다. 인자가 같은 $modal의 값은 이후에도 같다??
        const modalRecommend = await this.$modal(ModalRecommend, {});
        if (modalRecommend) {
          // 예를 선택했을 경우
          await this.$router.push({ name: 'CoachMatching' });
        }
        // 추천 유도 모달을 보기만 했어도 만료 시간으로 기록한다.
        this.$services.cookie.setTutorModalCloseAmount(0);
        this.$services.cookie.setTutorRecommendRejected();
      }
    },
    getFloatPopInOptions() {
      return ({
        componentPop: PurchaseAreaPopIn,
        options: { coachInfo: this.coachInfo },
        componentFloat: FloatPurchaseArea,
        optionsFloat: { coachInfo: this.coachInfo },
        title: '수업 신청하기',
        unregister: this.myCoachId === this.coachId,
      });
    },
  },
  mounted() {
    if (!TARGET_NODE) window.addEventListener('scroll', this.scrollHandler.bind(this));

    if (this.coachId) {
      this.$gtag.event('coach_detail_view', {
        event_category: 'detail_tutor',
        event_label: 'open',
        value: this.coachId,
      });
    }

    if (this.coachInfo?.lessonProducts?.length > 0) {
      const items = [];
      this.coachInfo?.lessonProducts.forEach(each => {
        items.push({
          item_id: each.lessonProductId,
          item_name: each.lesson.name,
          price: each.price * 10,
          item_brand: each.coachId,
          item_category: 'REALTIME_COACHING',
          currency: 'KRW',
        });
      });
      this.$gtag.event('view_item_list', {
        item_list_id: 'DetailTutor',
        item_list_name: 'DetailTutor',
        items,
      });
    }
  },
  beforeDestroy() {
    if (!TARGET_NODE) window.removeEventListener('scroll', this.scrollHandler.bind(this));
  },
  async beforeRouteLeave(to, from, next) {
    if (to?.name === 'CoachMatching') {
      // 추천 페이지 유도를 통해 추천 코치 페이지로 가는 경우에 막히면 안되므로 허용
      return next();
    }
    // 추천 코치 페이지로 유도
    const routedFromRecommend = getSessionStorage('router').get('routedFromCoachRecommend');
    if (routedFromRecommend === 'false' && !this.isCoach) {
      await this.induceRecommendTutor();
    }
    next();
  },
  async asyncData({ route, services }) {
    const coachId = route?.params?.coachId;
    const coachInfo = await services.coaching.getTutorDetail(coachId);
    const recommendLessonList = await services.coaching.getRecommendLesson();
    return { coachInfo, recommendLessonList };
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
@coach-category-height: 0px;

@media (@ds-up) {
  #app { overflow: unset;
    > .contents-wrapper { overflow: unset;}
  }
}

[coach-detail] { .bgc(#fff); .pt(40); .pb(64);
  .btn-prev-holder { .flex; .items-center;
    .btn-prev { .flex; .w(40); .h(40); transform: rotate(90deg); .bgc(#ebebf0); .br(12);
      &:hover {
        > img { .o(0.8);}
      }
      > img { .m(auto); .o(0.4);}
    }
    > span {.c(#787781); .fs(14); .ml(8);}
  }
  .coach-detail-wrap {.flex; flex-direction: column; .max-w(1280); .m(0, auto); .h(100%);}
  .coach-detail-inner {.flex; .pt(20);}
  .coach-info {.w(100%);
    .btn-holder {.w(100%); .mb(8);.flex;.flex-jc(flex-end);
      > button + button {.ml(10);}
    }
    //.profile {.h(137); .w(100%); .bgc(#aaa); }
    .tab {.mt(32); position: sticky; .t(@coach-category-height); .bgc(#fff); .z(10);
      .bar {.hide;}
      &.tab-float { .fix; .m(0); .t(@coach-category-height); .z(10);
        .bar {.abs; display: unset; .t(0); .l(-50%); .bgc(#fff); .h(61); .w(50vw); .z(-1); border-bottom: solid 1px #d8d8d8;}
      }

    }
    .tab-content { .rel; .max-w(816);
      &.tab-float { .pt(calc(60px + 32px)); }
    }
  }

  //&.with-top-banner {
  //  .coach-info .tab.tab-float { .t(@banner-height-pc)}
  //}

  .side {.min-w(416); .w(416); .h(647); .ml(48); position: sticky; .t(164); }
  .recommend-lesson {.mt(64);
    [recommend-lesson] {.pointer; .mt(24);}
  }

  [button-bookmark] {
    &:focus {outline-style: none;}
  }

  @media (max-width: 1279px) {
    .btn-prev-holder {.p(0, 58); .mb(20);}
    .coach-detail-inner { .p(0, 58); }
    .recommend-lesson {.p(0, 10);
      [heading] {.ml(48);}
    }
    .coach-info {
      .tab-content {.max-w(unset);}
    }
    //.side {.min-w(unset); .w(100%); .fix; .b(calc((100% - 80px) * -1)); .z(20); .l(0); .m(0); .t(unset);}
    .side {.hide}
  }

  @media (@tp-down) {
    .pt(20);
    .btn-prev-holder {.p(0, 20); .mb(20);}
    .coach-detail-inner { .p(0, 20); }
    .recommend-lesson {.p(0);
      [heading] {.ml(20);}
    }
    .coach-info .tab-content {
      &.tab-float { .pt(calc(49px + 32px)); }
    }
    .coach-info .tab.tab-float { .max-w(calc(100vw - 40px));
      .bar { .l(-20); .w(calc(100vw + 20px)); .h(49);}
    }
  }
  &.with-top-banner {
    .coach-info .tab.tab-float.hidden-header {
      .t(@banner-height-pc);
    }
  }

  @media (@ml-down) {
    .coach-info .tab.tab-float { transition: top ease-in-out 0.3s;
      &.hidden-header { .t(0);}
    }
    // 모바일에서는 스크롤 다운하면 배너 표기 안됨
    &.with-top-banner {
      .coach-info .tab.tab-float.hidden-header {
        .t(0);
      }
    }
  }

}
</style>
